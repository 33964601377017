<!-- <mat-toolbar style="height: 3vw !important">
    <span>Customer Search</span>     
</mat-toolbar> -->

<!-- Made changes  based on  user role CLIENT

<div *ngIf= "userRole == 'CLIENT' ">

</div>

-->



  
<mat-card  class="search-card" style=" margin-right: 1vw !important;">
  <mat-card-header>
    <mat-card-title style="font-size: 16px">
      Customer Search 
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <mat-tab-group  #searchTabs [selectedIndex]="selectedIndex" (selectedTabChange)="tabChanged($event)"  [class]="workorderService.getCustomTabCSSForOfficeId()">
      <mat-tab label="By Phone Number">
        <br>
        <mat-form-field appearance="outline">
          <mat-label>Phone Number (Numbers Only)</mat-label>
          <input [(ngModel)]="phoneNumber" matInput type="tel" placeholder="Phone Number" (ngModelChange)="phoneNumber = $event.toUpperCase()">
        </mat-form-field>
      </mat-tab>
      <mat-tab label="By Address">
      
        <br>

        <mat-form-field appearance="outline" style="padding-right: 0.5vw;">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="First Name" [(ngModel)]="addressCriteria.FirstName" (ngModelChange)="addressCriteria.FirstName = $event.toUpperCase()"/>
        </mat-form-field>
        <mat-form-field appearance="outline" class="second-field" style="padding-right: 0.5vw;">
          <mat-label>Last Name</mat-label>
          <input matInput placeholder="Last Name" [(ngModel)]="addressCriteria.LastName" (ngModelChange)="addressCriteria.LastName = $event.toUpperCase()"/>
        </mat-form-field>
        <mat-form-field appearance="outline" style="padding-right: 0.5vw;">
          <mat-label>Full Name</mat-label>
          <input matInput placeholder="Full Name" [(ngModel)]="addressCriteria.FullName" (ngModelChange)="addressCriteria.FullName = $event.toUpperCase()"/>
        </mat-form-field>
        <br>
        <mat-form-field appearance="outline" style="padding-right: 0.5vw;">
          <mat-label>Street Number</mat-label>
          <input matInput placeholder="Street Number" [(ngModel)]="addressCriteria.StreetAddressNumber" (ngModelChange)="addressCriteria.StreetAddressNumber = $event.toUpperCase()"/>
        </mat-form-field>
        <mat-form-field appearance="outline" class="second-field" style="padding-right: 0.5vw;">
          <mat-label>Street Name</mat-label>
          <input matInput placeholder="Street Name" [(ngModel)]="addressCriteria.StreetAddressName" (ngModelChange)="addressCriteria.StreetAddressName = $event.toUpperCase()"/>
        </mat-form-field>
        <mat-form-field appearance="outline" style="padding-right: 0.5vw;">
          <mat-label>Apartment</mat-label>
          <input matInput placeholder="Apt" [(ngModel)]="addressCriteria.Apartment" (ngModelChange)="addressCriteria.Apartment = $event.toUpperCase()"/>
        </mat-form-field>
        <mat-form-field appearance="outline" class="second-field" style="padding-right: 0.5vw;">
          <mat-label>City</mat-label>
          <input matInput placeholder="City" [(ngModel)]="addressCriteria.City" (ngModelChange)="addressCriteria.City = $event.toUpperCase()"/>
        </mat-form-field>
        <br>
        <mat-form-field appearance="outline" style="padding-right: 0.5vw;">
          <mat-label>State</mat-label>
          <input matInput placeholder="State" [(ngModel)]="addressCriteria.State" (ngModelChange)="addressCriteria.State = $event.toUpperCase()"/>
        </mat-form-field>
        <mat-form-field appearance="outline" class="second-field" style="padding-right: 0.5vw;">
          <mat-label>Zip Code</mat-label>
          <input matInput placeholder="Zip Code" [(ngModel)]="addressCriteria.Zipcode"  (ngModelChange)="addressCriteria.Zipcode = $event.toUpperCase()"/>
        </mat-form-field>
      </mat-tab>
      <mat-tab label="By Account">
        <br>
        <mat-form-field appearance="outline">
          <mat-label>Account Number to Search For</mat-label>
          <input [(ngModel)]="accountNumber" matInput placeholder="Account Number" (ngModelChange)="accountNumber = $event.toUpperCase()"/>
        </mat-form-field>
      </mat-tab>
      <mat-tab label="By Email">
        <br>
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input [(ngModel)]="email" matInput type="email" placeholder="Email" (ngModelChange)="email = $event.toUpperCase()"/>
        </mat-form-field>
      </mat-tab>
      <mat-tab label="By Workorder">
        <br>
        <mat-form-field appearance="outline" style="padding-right: 2.2vw; width:17.6vw">
          <mat-label>Workorder Name</mat-label>
          <input [(ngModel)]="workorderName" matInput placeholder="Workorder" (ngModelChange)="workorderName = $event.toUpperCase()"/>
        </mat-form-field>
        <mat-form-field appearance="outline" style="padding-right: 2.2vw;width:17.6vw">
          <mat-label>Program</mat-label>
          <mat-select [(ngModel)]="selectedProgram">
            <mat-option *ngFor="let program of programList" [value]="program" style="font-size: 11px;">
              {{program.ProgramName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" style="padding-right: 2.2vw;width:17.6vw">
          <mat-label>Status</mat-label>
          <mat-select [(value)]="selectedStatus">
            <mat-option *ngFor="let status of statusList" [value]="status" style="font-size: 11px;">{{status.JobStatus}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <br>
        <mat-form-field appearance="outline" style="padding-right: 2.2vw; width:17.6vw">
          <mat-label>Status - From Date</mat-label>
          <input matInput [matDatepicker]="fromPicker" placeholder="Choose a date" [min]="minDate" [max]="maxDate"
            [(ngModel)]="startDate" (ngModelChange)="startDate = $event.toUpperCase()">
          <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
          <mat-datepicker #toPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" style="padding-right: 2.2vw; width:17.6vw">
          <mat-label>Status - To Date</mat-label>
          <input matInput [matDatepicker]="toPicker" placeholder="Choose a date" [min]="minDate" [max]="maxDate"
            [(ngModel)]="endDate" (ngModelChange)="endDate = $event.toUpperCase()">
          <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
          <mat-datepicker #fromPicker></mat-datepicker>
        </mat-form-field>
        <br>
        <label *ngIf="noDataEntered" style="color: red">Please enter atleast one field.</label>
        <label *ngIf="dateMismatch" style="color: red">To Date to be greater than From Date</label>
      </mat-tab>

      <mat-tab label="By Serial Number">
        <br>
        <mat-form-field appearance="outline">
          <mat-label>Serial Number to Search For</mat-label>
          <input [(ngModel)]="serialno" matInput placeholder="Serial Number" (ngModelChange)="serialno = $event.toUpperCase()"/>
        </mat-form-field>
      </mat-tab>

      <mat-tab label="By PSD Job Number" *ngIf="userService.dbBBCSId != ''">
        <br>
        <mat-form-field appearance="outline">
          <mat-label>PSD Job Number to Search For</mat-label>
          <input [(ngModel)]="jobno" matInput placeholder="PSD Job Number"
            (ngModelChange)="jobno = $event.toUpperCase()" />
        </mat-form-field>
      </mat-tab>

    </mat-tab-group>
  </mat-card-content>
  <mat-card-actions>
    <button  mat-raised-button color="primary" (click)="doSearch()" style="font-size: 12px !important">SEARCH</button>
    <button  mat-raised-button color="primary" (click)="doClear()" style="font-size: 12px !important">CLEAR</button>
    <span *ngIf="enableEnrollCustomer==1">
    <button  mat-raised-button color="primary" (click)="enrollCustomer()" style="font-size: 12px !important;margin-left:0px;">ADD NEW CUSTOMER</button>
    </span>
    <br>
    <br>
    <div *ngIf="displayMasterSearch">
      <mat-checkbox style="margin-left: 10px;" [(ngModel)]="customerService.includeMaster"
        style="font-size:1vw !important; margin-left: 0.8vw">Include Master File in search</mat-checkbox>
    </div>

  </mat-card-actions>
</mat-card>


<mat-card class="list-card" style="margin-right: 1vw !important" *ngIf="displayResult">
  <mat-card-header>
    <mat-card-title style="font-size: 16px">
      <span style="font-size: 1.5vw">{{ customers.length }} Search Results</span>
    </mat-card-title>
    <mat-card-subtitle>Highlighted rows are from Master File</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content style="margin-left:0.3vw">
    <div *ngIf="searching">
      <mat-spinner [diameter]="25" color="primary"></mat-spinner>
    </div>

    <div *ngIf="customers.length < 1 && !searching" class="search-card">
      <h3>No Search Results</h3>
    </div>

    <table style="width: 100% !important" mat-table [dataSource]="dataSource" matSort (matSortChange)="sortCustData($event)" class="mat-elevation-z8" *ngIf="customers.length > 0 && !searching">
      <ng-container matColumnDef="name">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let cust"> {{cust.Name | titlecase}} </td>
      </ng-container>
     
      <ng-container matColumnDef="account">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header>Account No</th>
        <td mat-cell *matCellDef="let cust"> {{cust.AccountNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header>Phone</th>
        <td mat-cell *matCellDef="let cust"> {{cust.PrimaryPhone}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let cust"> {{cust.Email}} </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header>Service Address</th>
        <td mat-cell *matCellDef="let cust"> {{cust.ServiceStreetAddress1}} </td>
      </ng-container>

      <ng-container matColumnDef="zip">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header>Service Zip</th>
        <td mat-cell *matCellDef="let cust"> {{cust.ServiceZipcode}} </td>
      </ng-container>

      <ng-container matColumnDef="providerName">
          <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header>Provider</th>
          <td mat-cell *matCellDef="let cust"> {{cust.ProviderName}} </td>
      </ng-container>

      <ng-container matColumnDef="premiseid">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header>Premise ID</th>
        <td mat-cell *matCellDef="let cust"> {{cust.PremiseId}} </td>
      </ng-container>

      <ng-container matColumnDef="externalid">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header>External ID</th>
        <td mat-cell *matCellDef="let cust"> {{cust.ExternalId}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="curr-cust"  [class.master-cust]="row.FromMaster" (dblclick)="handleClick(row)"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10,25,50,100]" [pageSize]="25" showFirstLastButtons></mat-paginator>
  </mat-card-content>
</mat-card>

<mat-card class="list-card" style="margin-right: 1vw !important" *ngIf="displayWorkorder">
  <mat-card-header>
    <mat-card-title style="font-size: 16px">
      <span style="font-size: 1.5vw">{{ workorders.length }} Search Results</span>
    </mat-card-title>
    <mat-card-subtitle>Highlighted rows are from Master File</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content style="margin-left:0.3vw">
    <div *ngIf="workorderSearching">
      <mat-spinner [diameter]="25" color="primary"></mat-spinner>
    </div>

    <div *ngIf="workorders.length < 1 && !workorderSearching" class="search-card">
      <h3>No Search Results</h3>
    </div>

    <table style="width: 100% !important" mat-table [dataSource]="dataSource1" class="mat-elevation-z8" matSort (matSortChange)="sortWOData($event)" *ngIf="workorders.length > 0 && !workorderSearching">
      <ng-container matColumnDef="name">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">Name</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.Name}} </td>
      </ng-container>

      <ng-container matColumnDef="program">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">Program</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.Program}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">Status</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.Status}} </td>
      </ng-container>

      <ng-container matColumnDef="customer">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">Customer</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.Customer | titlecase}} </td>
      </ng-container>

      <ng-container matColumnDef="statusDate">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">Date</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.StatusDate | date:'M/dd/yy'}} </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">Address</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.Address | titlecase}} </td>
      </ng-container>

      <ng-container matColumnDef="city">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">City</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.City | titlecase}} </td>
      </ng-container>
      
      <ng-container matColumnDef="premiseid">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">Premise</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.PremiseId}} </td>
      </ng-container>

      <ng-container matColumnDef="externalid">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">External Id</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.ExternalId}} </td>
      </ng-container>

      <ng-container matColumnDef="externalKey">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">External Key</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.Externalkey}} </td>
      </ng-container>

      <ng-container matColumnDef="buttonSchedule">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw"></th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          <mat-button matTooltip="Schedule" *ngIf="workorder.StatusNumber == 190 && workorder.BucketScheduleEnabled== 0" (click)="navigateToSchedule(workorder)"><mat-icon>watch</mat-icon></mat-button>
          <mat-button matTooltip="Reschedule" *ngIf="workorder.StatusNumber == 200" (click)="navigateToSchedule(workorder)"><mat-icon>update</mat-icon></mat-button>
          <mat-button matTooltip="Bucket Scheduling" *ngIf="workorder.StatusNumber == 190 && workorder.BucketScheduleEnabled== 1" (click)="navigateToBucketSchedule(workorder)"><mat-icon>date_range</mat-icon></mat-button>
         </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns1;" class="curr-cust"
        [class.master-cust]="row.FromMaster" (dblclick)="handleWorkorderClick(row)"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10,25,50,100]" [pageSize]="25" showFirstLastButtons></mat-paginator>
  </mat-card-content>
</mat-card>

<!-- Serial number search display-->

<mat-card class="list-card" style="margin-right: 1vw !important" *ngIf="displaySerial">
  <mat-card-header>
    <mat-card-title style="font-size: 16px">
      <span style="font-size: 1.5vw">{{ workorders.length }} Search Results </span>
    </mat-card-title>
    <mat-card-subtitle>Highlighted rows are from Master File</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content style="margin-left:0.3vw">
    <div *ngIf="workorderSearching">
      <mat-spinner [diameter]="25" color="primary"></mat-spinner>
    </div>

    <div *ngIf="workorders.length < 1 && !workorderSearching" class="search-card">
      <h3>No Search Results</h3>
    </div>

    <table style="width: 100% !important" mat-table [dataSource]="dataSource1" class="mat-elevation-z8" matSort (matSortChange)="sortWOData($event)" *ngIf="workorders.length > 0 && !workorderSearching">
      <ng-container matColumnDef="name">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">Name</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.Name}} </td>
      </ng-container>

      <ng-container matColumnDef="program">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">Program</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.Program}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">Status</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.Status}} </td>
      </ng-container>

      <ng-container matColumnDef="customer">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">Customer</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.Customer | titlecase}} </td>
      </ng-container>

      <ng-container matColumnDef="statusDate">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">Date</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.StatusDate | date:'M/dd/yy'}} </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">Address</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.Address | titlecase}} </td>
      </ng-container>

      <ng-container matColumnDef="city">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">City</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.City | titlecase}} </td>
      </ng-container>

      <ng-container matColumnDef="serialno">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">SerialNo</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.SerialNo}} </td>
      </ng-container>
      
      <ng-container matColumnDef="premiseid">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">Premise</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.PremiseId}} </td>
      </ng-container>

      <ng-container matColumnDef="externalid">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">External Id</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.ExternalId}} </td>
      </ng-container>

      <ng-container matColumnDef="externalKey">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">External Key</th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          {{workorder.Externalkey}} </td>
      </ng-container>

      <ng-container matColumnDef="buttonSchedule">
        <th mat-header-cell [class]="workorderService.getCustomCSSForOfficeId(true)" *matHeaderCellDef mat-sort-header style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw"></th>
        <td mat-cell *matCellDef="let workorder" style="font-size: 1vw; padding-right: 1vw;padding-left: 1vw">
          <mat-button matTooltip="Schedule" *ngIf="workorder.StatusNumber == 190 && workorder.BucketScheduleEnabled== 0" (click)="navigateToSchedule(workorder)"><mat-icon>watch</mat-icon></mat-button>
          <mat-button matTooltip="Reschedule" *ngIf="workorder.StatusNumber == 200" (click)="navigateToSchedule(workorder)"><mat-icon>update</mat-icon></mat-button>
          <mat-button matTooltip="Bucket Scheduling" *ngIf="workorder.StatusNumber == 190 && workorder.BucketScheduleEnabled== 1" (click)="navigateToBucketSchedule(workorder)"><mat-icon>date_range</mat-icon></mat-button>
         </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns2;" class="curr-cust"
        [class.master-cust]="row.FromMaster" (dblclick)="handleWorkorderClick(row)"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10,25,50,100]" [pageSize]="25" showFirstLastButtons></mat-paginator>
  </mat-card-content>
</mat-card>

