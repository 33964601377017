import { Injectable } from '@angular/core';
import { Customer } from '../models/customer';
import { UtilityService } from './utility.service';
import { environment } from '../../environments/environment';
import { Observable, of, throwError, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AddressSearch } from '../models/addresssearch';
import { AnyAaaaRecord } from 'dns';
import { UserService } from './user.service';
import { ProviderResource, UsageReadings } from '../models/usage-readings';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    currentCustomer: Customer;
    private custSearchWsUrl1;
    private custSearchWsUrl2;
    programSponsorId: any;
    customerList: any = Array<Customer>();
    storedCustomer$: Observable<any>;
    private customerSubject = new Subject<any>(); // what is this
    includeMaster: boolean = true;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService,
        private userService: UserService
    ) {
        this.storedCustomer$ = this.customerSubject.asObservable();
        this.custSearchWsUrl1 = environment.apiUrl + 'customer/';
        this.custSearchWsUrl2 = environment.apiUrl + 'customersearch/';
    }

    public isBbcs() {
        return this.utilityService.currentProgramSponsor.SourceId === 'BBCS';
    }

    public getHeaders() {
        const email = localStorage.getItem('loggedInUserEmail');
        const progamsponsorid = this.utilityService.currentUtility.ProgramsponsorId;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-email': email,
                'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
            })
        };

        return httpOptions;
    }

    getCustomerByPhone(phoneNo: string): Observable<Customer[]> {

        const searchUrl = this.custSearchWsUrl1 + this.utilityService.currentUtility.OfficeId
            + '/phone/' + phoneNo + '?code=' + environment.apiCode + '&master=' + this.includeMaster;
        return this.http.get<Customer[]>(searchUrl, this.getHeaders())
            .pipe(
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    getCustomerByEmail(email: string): Observable<Customer[]> {

        const searchUrl = this.custSearchWsUrl1 + this.utilityService.currentUtility.OfficeId
            + '/email/' + email + '?code=' + environment.apiCode + '&master=' + this.includeMaster;

        return this.http.get<Customer[]>(searchUrl, this.getHeaders())
            .pipe(
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    getCustomerBySerialno(serialno: string): Observable<Customer[]> {

        const searchUrl = this.custSearchWsUrl2 + this.utilityService.currentUtility.OfficeId
            + '/serialnumber/' + serialno + '?code=' + environment.apiCode + '&master=' + this.includeMaster + '&org_cd=' + this.userService.orgCd;

        return this.http.get<Customer[]>(searchUrl, this.getHeaders())
            .pipe(
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    getCustomerByPSDJobId(jobid: string): Observable<Customer[]> {
        const searchUrl = this.custSearchWsUrl2 + this.utilityService.currentUtility.OfficeId
            + '/CustomSearchId/' + jobid + '?code=' + environment.apiCode + '&master=' + this.includeMaster + '&org_cd=' + this.userService.orgCd;
        return this.http.get<Customer[]>(searchUrl, this.getHeaders())
            .pipe(
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    getCustomerByAddress(criteria: AddressSearch): Observable<Customer[]> {
        const body = {
            FirstName: criteria.FirstName,
            LastName: criteria.LastName,
            FullName: criteria.FullName,
            StreetAddressNumber: criteria.StreetAddressNumber,
            StreetAddressName: criteria.StreetAddressName,
            Apartment: criteria.Apartment,
            City: criteria.City,
            State: criteria.State,
            Zipcode: criteria.Zipcode,
            Sa:criteria.Zipcode
        };
        console.log(body);

        const searchUrl = this.custSearchWsUrl1 + this.utilityService.currentUtility.OfficeId
            + '/address' + '?code=' + environment.apiCode + '&master=' + this.includeMaster;


        return this.http.post<Customer[]>(searchUrl, body, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    getCustomerByAccountNo(accountNo: string): Observable<Customer[]> {
        const searchUrl = this.custSearchWsUrl1 + this.utilityService.currentUtility.OfficeId
            + '/account/' + accountNo + '?code=' + environment.apiCode + '&master=' + this.includeMaster;

        return this.http.get<Customer[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    getCustomerById(id: string): Observable<Customer[]> {

        const searchUrl = this.custSearchWsUrl1 + this.utilityService.currentUtility.OfficeId
            + '/' + id + '?code=' + environment.apiCode;

        return this.http.get<Customer[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    public createCustomer(cust: Customer): Observable<any> {
        const custObj = cust;
        custObj.PrimaryPhone = custObj.PrimaryPhone.replace(/[- )(]/g, '');
        custObj.AlternatePhone = custObj.AlternatePhone.replace(/[- )(]/g, '');
        const searchUrl = this.custSearchWsUrl1 + this.utilityService.currentUtility.OfficeId
            + '?code=' + environment.apiCode;

        custObj.ProgramSponsorId = this.utilityService.currentUtility.ProgramsponsorId;

        return this.http.post(searchUrl, custObj, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }
    public updateaddress(cust: any,PersonCompanyId:any): Observable<any> {


        const searchUrl = environment.apiUrl + 'customer/addressupdate/' + this.utilityService.currentUtility.OfficeId+'/'
                  + PersonCompanyId + '?code=' + environment.apiCode;
  
  
  
       // console.log(cust);
       // console.log(searchUrl);
              return this.http.post(searchUrl, cust, this.getHeaders())
                  .pipe(map(response => response),
                      catchError(err => this.utilityService.handleError(err, this, []))
                  );
      }
      public updatecustomername(cust: any,PersonCompanyId:any): Observable<any> {


        const searchUrl = environment.apiUrl + 'customer/detailsupdate/' + this.utilityService.currentUtility.OfficeId+'/'
                  + PersonCompanyId + '?code=' + environment.apiCode;
  
  
  
       console.log(cust);
        console.log(searchUrl);
              return this.http.post(searchUrl, cust, this.getHeaders())
                  .pipe(map(response => response),
                      catchError(err => this.utilityService.handleError(err, this, []))
                  );
      }
    workorderSearch(workorderID, program, status, fromDate, toDate) {
        let body:any;
       
        const searchUrl = environment.apiUrl + 'workorder/' + this.utilityService.currentUtility.OfficeId
            + '/workordersearch' + '?code=' + environment.apiCode + '&master=' + this.includeMaster + '&org_cd=' + this.userService.orgCd;
        //console.log(searchUrl);
            if (toDate != null) {
            toDate = toDate.toJSON()
        }
        if(this.utilityService.currentUtility.Source == "BBCS"){
             body = {
                ProgramSponsorId: this.utilityService.currentUtility.ProgramsponsorId,
                WorkorderId: workorderID,
                ProgramId: program,
                WorkorderStatusNumber: status,
                StatusStartDate: fromDate.toJSON(),
                StausEndDate: toDate
            };
        }
        else
        {
             body = {
                ProgramSponsorId: this.utilityService.currentUtility.ProgramsponsorId,
                WorkorderId: workorderID,
                ProgramId: program,
                WorkorderStatus: status,
                StatusStartDate: fromDate.toJSON(),
                StausEndDate: toDate
            };
        }
           
        console.log(body);
        return this.http.post(searchUrl, body, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    public updateCustomer(cust: Customer): Observable<any> {

        const searchUrl = this.custSearchWsUrl1 + this.utilityService.currentUtility.OfficeId
            + '/' + cust.Id
            + '?code=' + environment.apiCode;

        const body = {
            PrimaryPhone: cust.PrimaryPhone.replace(/[- )(]/g, ''),
            AlternatePhone: cust.AlternatePhone.replace(/[- )(]/g, ''),
            DoNotCall: cust.DoNotCall,
            DoNotEmail: cust.DoNotEmail,
            DoNotMail: cust.DoNotMail,
            Email: cust.Email
        };

        this.storedCustomers(cust);
        return this.http.patch(searchUrl, body, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }


    storedCustomers(data) {
        this.customerSubject.next(data);//usage
    }

    public getUsage (personCompanyId: string, providerResourceId: string): Observable<UsageReadings[]> {
        const searchUrl = environment.apiUrl + 'customer/usage/' + this.utilityService.currentUtility.OfficeId + '/'
                  + personCompanyId + '/' + providerResourceId + '?code=' + environment.apiCode;
   
        return this.http.get(searchUrl, this.getHeaders())
                  .pipe(map(response => response),
                      catchError(err => this.utilityService.handleError(err, this, []))
                  );
      }

    public uploadUsage (personCompanyId: string, providerResourceId: string, usageReadings: UsageReadings[]): Observable<any> {
        const searchUrl = environment.apiUrl + 'customer/uploadUsage/' + this.utilityService.currentUtility.OfficeId + '/'
                  + personCompanyId + '/' + providerResourceId + '?code=' + environment.apiCode;
   
        return this.http.post(searchUrl, usageReadings, this.getHeaders())
                  .pipe(map(response => response),
                      catchError(err => this.utilityService.handleError(err, this, []))
                  );
      }

      public getProviderResources(): Observable<ProviderResource[]> {
        const url = environment.apiUrl + 'customer/usage/providers/' + 
        this.utilityService.currentUtility.OfficeId + '?code=' + environment.apiCode;

        return this.http.get(url, this.getHeaders())
                  .pipe(map(response => response),
                      catchError(err => this.utilityService.handleError(err, this, []))
                  );
      }

}
