import { Component, OnInit, ViewChild, Output, EventEmitter, HostListener, Input, ComponentFactoryResolver } from '@angular/core';
import { AddressSearch } from '../models/addresssearch';
import { Customer } from '../models/customer';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { UtilityService } from '../services/utility.service';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';
import { CustomerService } from '../services/customer.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { WaitDialogComponent } from '../util/wait-dialog/wait-dialog.component';
import { DataTransferService } from '../services/data-transfer.service';
import Swal from 'sweetalert2';
import { WorkorderService } from '../services/workorder.service';
import { Workorder } from '../models/workorder';
import { ModuleAccessService } from '../services/module-access.service';
import { EnrollmentService } from '../services/enrollment.service';
import { LocationsearchService } from '../services/locationsearch.service';
import { MatPaginator } from '@angular/material/paginator';
import { UserService } from '../services/user.service';


@Component({
    selector: 'app-customer-search',
    templateUrl: './customer-search.component.html',
    styleUrls: ['./customer-search.component.scss']
})

export class CustomerSearchComponent implements OnInit {
    @ViewChild(MatPaginator, { static: false })
    set paginator(value: MatPaginator) {
        if(this.dataSource.filteredData.length != 0){
             this.dataSource.paginator = value;
             console.log('datasoure:', this.dataSource);
        }
        if(this.dataSource1.filteredData.length != 0){
            console.log('datasoure1:', this.dataSource1);
            this.dataSource1.paginator = value;
        }
    };
    @ViewChild('searchTabs', { static: true }) searchTabs: MatTabGroup;
    className: any;
    phoneNumber: any;
    email: string;
    serialno: any;
    utilcode: string;
    statusData:any;
    addressCriteria: AddressSearch;
    accountNumber: any;
    customers: any;
    workorders: any;
    serialnumbers:any;
    searching = false;
    workorderSearching = false;
    displaySearch = true;
    displayResult = false;
    displayWorkorder = false;
    displaySerial = false;
    displayedColumns: string[] = ['name', 'account', 'phone', 'email', 'address', 'zip', 'providerName','premiseid','externalid'];
    displayedColumns1: string[] = ['name', 'program', 'status', 'customer', 'statusDate', 'address', 'city', 'externalKey','premiseid','externalid','buttonSchedule'];
    displayedColumns2: string[] = ['name', 'program', 'status', 'customer', 'statusDate', 'address', 'city','serialno', 'externalKey','premiseid','externalid','buttonSchedule'];
    dataSource1 = new MatTableDataSource<Workorder>();
    dataSource = new MatTableDataSource<Customer>();
    programList: Programs[];
    selectedProgram: Programs;
    statusList: Status[];
    selectedStatus: Status;
    noDataEntered = false;
    showWorkorderSearch = true;
    workorderName: any;
    startDate: Date;
    endDate: Date;
    maxDate: Date = new Date();
    minDate: Date = new Date(2000, 0, 1, 0, 0, 0);
    dateMismatch = false;
    selectedIndex: any;
    displayMasterSearch = true;
    userRole:any;
    enableEnrollCustomer:any;
    jcplSettings:any;
    key: any;
    jobno: any;
    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.key = event.key;
        if (event.key === "Enter" && this.searchTabs.selectedIndex != -1) {
            this.doSearch();
        }
    }
    
    constructor(
        private utilityService: UtilityService,
        private dataTransfer: DataTransferService,
        public customerService: CustomerService,
        private router: Router, private locationSearchService:LocationsearchService,
        private dialog: MatDialog, private enrollmentService: EnrollmentService,
        public workorderService: WorkorderService, private moduleAccess: ModuleAccessService,
        public userService: UserService) {
        this.addressCriteria = new AddressSearch();

        this.utilityService.utilityMethod$.subscribe(res => {
            this.programList = [];
            this.statusList = [];
            this.loadWorkorderPrograms();
            this.loadWorkorderStatus();
            if (this.utilityService.currentUtility.OfficeId === "SAN1") {
                this.displayMasterSearch = false;
    
            } else {
                if (this.selectedIndex === 4 ) {
                    this.displayMasterSearch = false;
                }
                else {
                    this.displayMasterSearch = true;
                }
            }
        });
    }

    ngOnInit() {
        
        this.userRole=localStorage.getItem('selectedUtilUserRole');
        console.log('userRole:',this.userRole);
        //To enable or disable add new customer for JCPL - START
        this.utilityService.getSelfScheduleSettings(this.utilityService.currentUtility.OfficeId).subscribe((res) => {
        this.jcplSettings=res;
        this.enableEnrollCustomer=this.jcplSettings.enableenrollcustomer;
        console.log('officeid:',this.utilityService.currentUtility.OfficeId);
        localStorage.setItem('officeId', this.utilityService.currentUtility.OfficeId);
        });
        //To enable or disable add new customer for JCPL - END

        if (localStorage.getItem('selectedTabIndex') === undefined) {
            this.selectedIndex = 0;
        } else {
            this.selectedIndex = Number(localStorage.getItem('selectedTabIndex'));
        }

        this.customerService.currentCustomer = undefined;
        if (this.utilityService.currentUtility.OfficeId === "SAN1") {
            this.displayMasterSearch = false;

        } else {
            if (this.selectedIndex === 4 || this.selectedIndex === 5 || this.selectedIndex === 6) {
                
                this.displayMasterSearch = false;
            }
            else {
                this.displayMasterSearch = true;
            }
        }

        this.dataTransfer.myMethod(false);
        this.loadWorkorderPrograms();
        this.loadWorkorderStatus();
        this.phoneNumber = localStorage.getItem('phoneNumber');
        this.accountNumber = localStorage.getItem('accountNumber');
        this.email = localStorage.getItem('email');
        this.serialno = localStorage.getItem('serialno');
        this.jobno = localStorage.getItem('jobno');
        this.addressCriteria.FirstName = localStorage.getItem('FirstName') === null ? '' : localStorage.getItem('FirstName');
        this.addressCriteria.LastName = localStorage.getItem('LastName') === null ? '' : localStorage.getItem('LastName');
        this.addressCriteria.FullName = localStorage.getItem('FullName') === null ? '' : localStorage.getItem('FullName');
        this.addressCriteria.Apartment = localStorage.getItem('Apartment') === null ? '' : localStorage.getItem('Apartment');
        this.addressCriteria.City = localStorage.getItem('City') === null ? '' : localStorage.getItem('City');
        this.addressCriteria.State = localStorage.getItem('State') === null ? '' : localStorage.getItem('State');
        this.addressCriteria.StreetAddressName = localStorage.getItem('StreetAddressName') === null ? '' : localStorage.getItem('StreetAddressName');
        this.addressCriteria.StreetAddressNumber = localStorage.getItem('StreetAddressNumber') === null ? '' : localStorage.getItem('StreetAddressNumber');
        this.addressCriteria.Zipcode = localStorage.getItem('Zipcode') === null ? '' : localStorage.getItem('Zipcode');
        this.workorderName = localStorage.getItem('workorderName') === null ? '' : localStorage.getItem('workorderName');
        this.doClear();
    }

    sortWOData(sort: Sort) {
        const data = this.workorders;
        if (!sort.active || sort.direction === '') {
            this.dataSource1.data = data;
            return;
        }

        this.dataSource1.data = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'name': return this.compare(a.Name, b.Name, isAsc);
                case 'program': return this.compare(a.Program, b.Program, isAsc);
                case 'status': return this.compare(a.Status, b.Status, isAsc);
                case 'customer': return this.compare(a.Customer, b.Customer, isAsc);
                case 'statusDate': return this.compare(a.StatusDate, b.StatusDate, isAsc);
                case 'address': return this.compare(a.Address, b.Address, isAsc);
                case 'city': return this.compare(a.City, b.City, isAsc);
                case 'serialno': return this.compare(a.SerialNo, b.SerialNo, isAsc);
                case 'externalKey': return this.compare(a.Externalkey, b.Externalkey, isAsc);
                case 'premise': return this.compare(a.PremiseId, b.PremiseId, isAsc);
                case 'externalid': return this.compare(a.ExternalId, b.ExternalId, isAsc);
                default: return 0;
            }
        });
    }

    


    sortCustData(sort: Sort) {
        const data = this.customers;
        if (!sort.active || sort.direction === '') {
            this.dataSource.data = data;
            return;
        }

        this.dataSource.data = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'name': return this.compare(a.Name, b.Name, isAsc);
                case 'account': return this.compare(a.AccountNumber, b.AccountNumber, isAsc);
                case 'phone': return this.compare(a.PrimaryPhone, b.PrimaryPhone, isAsc);
                case 'email': return this.compare(a.Email, b.Email, isAsc);
                case 'serialno': return this.compare(a.SerialNumber, b.SerialNumber, isAsc);
                case 'zip': return this.compare(a.MailingZipcode, b.MailingZipcode, isAsc);
                case 'address': return this.compare(a.MailingStreetAddress1, b.MailingStreetAddress1, isAsc);
                case 'premiseid': return this.compare(a.PremiseId, b.PremiseId, isAsc);
                case 'externalid': return this.compare(a.ExternalId, b.ExternalId, isAsc);
                default: return 0;
            }
        });
    }

    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
        this.displayResult = false;
        this.dataSource.data = [];
        this.dataSource1.data = [];

        if (this.utilityService.currentUtility.OfficeId === "SAN1") {
            this.displayMasterSearch = false;

        } else {
            if (tabChangeEvent.index == 4 || tabChangeEvent.index == 5 || tabChangeEvent.index == 6) {
                this.displayWorkorder = false;
                this.displayMasterSearch = false;
            }
            else {
                this.displayWorkorder = false;
                this.displayMasterSearch = true;
            }
        }

    }


    ngAfterContentChecked() {
        if (this.utilityService.firstDefaultSet) {
            this.utilityService.firstDefaultSet = false;
            if (this.utilcode !== null && this.phoneNumber !== null) {
                this.doSearch();
            }
        }
    }


    loadWorkorderPrograms() {
        this.workorderService.getWorkorderPrograms().subscribe(res => {
            if (res !== null) {
                if (Array.isArray(res.Programs)) {
                    this.programList = res.Programs;
                   // console.log('programlist:',this.programList);
                }
            }

        }, err => {
            console.log(err);
        });
    }

    loadWorkorderStatus() {
        this.workorderService.getWorkorderStatus().subscribe(res => {
            if (res !== null) {
                if (Array.isArray(res.Status)) {
                    this.statusList = res.Status;
                }

            }

        }, err => {
            console.log(err);
        });
    }


    public doSearch() {

    
        if (this.searchTabs !== undefined && this.searchTabs != null && this.searchTabs.selectedIndex != null) {
            localStorage.setItem('selectedTabIndex', this.searchTabs.selectedIndex.toString());
        }

        
        switch (this.searchTabs.selectedIndex) {
            case 0:

                this.doPhoneSearch();
                break;
            case 1:

                this.doAddressSearch();
                break;
            case 2:
            
                this.doAccountSearch();
                break;
            
            case 3:

                this.doEmailSearch();
                break;
            case 4:

                this.doWorkorderSearch();
                break;

            case 5:

                this.doSerialnoSearch();
                break;   
            
            case 6:

                this.doPSDJobnoSearch();
                break; 
            default:
                break;
        }
    
    }

    goBack() {
        this.displaySearch = true;
        this.displayResult = false;
        this.displayWorkorder = false;
        this.displaySerial = false;
    }

    private doWorkorderSearch() {
        this.dateMismatch = false;
        let start: Date;
        let end: Date;
        this.workorders = [];
        if ((this.workorderName == null || this.workorderName == undefined || this.workorderName == "")
            && (this.selectedProgram == null || this.selectedProgram == undefined) && (this.selectedStatus == null
                || this.selectedStatus == undefined) && (this.startDate == undefined || this.startDate == null) && (this.endDate == undefined || this.endDate == null)) {
            this.noDataEntered = true;
        } else {
            this.noDataEntered = false;
            if (this.startDate === undefined && this.endDate === undefined) {
                start = new Date(2000, 0, 1, 0, 0, 0);
                end = null;
             /* end.setDate(end.getDate() + 1);
                end.setHours(23, 59, 59); */
            }
            else if (this.startDate !== undefined && this.endDate === undefined) {

                start = this.startDate;
                end = new Date();
                end.setDate(end.getDate() + 1);
                end.setHours(23, 59, 59);

            }
            else if (this.startDate === undefined && this.endDate !== undefined) {
                start = new Date(2000, 0, 1, 0, 0, 0);
                end = this.endDate;
                end.setDate(end.getDate() + 1);
                end.setHours(23, 59, 59);
            }
            else {
                if (this.startDate <= this.endDate) {
                    this.dateMismatch = false;
                    start = this.startDate;
                    end = this.endDate;
                    end.setDate(end.getDate() + 1);
                    end.setHours(23, 59, 59);
                } else {
                    this.dateMismatch = true;

                }

            }


            if (this.dateMismatch === false) {
                this.displayWorkorder = true;
                this.displaySerial = false;
                this.displayResult = false;
                this.workorderSearching = true;

                this.workorderName = (this.workorderName === undefined || this.workorderName === null) ? "" : this.workorderName;
                let programId = (this.selectedProgram === undefined || this.selectedProgram === null) ? "" : this.selectedProgram.ProgramId;
                
                if(this.utilityService.currentUtility.Source == "BBCS"){
                    this.statusData = (this.selectedStatus === undefined || this.selectedStatus === null) ? "-1" : this.selectedStatus.JobStatusNumber;
                }
                else
                {
                    this.statusData = (this.selectedStatus === undefined || this.selectedStatus === null) ? "" : this.selectedStatus.JobStatus;

                }

                localStorage.setItem('workorderName', this.workorderName);

                this.customerService.workorderSearch(this.workorderName, programId, this.statusData, start, end).subscribe((res: []) => {

                    if (res !== null || res.length > 0) {
                        this.endWorkorderSearch(res);
                    }

                }, err => {
                    this.workorderSearching = false;
                })
            }
        }
    }

    private doPhoneSearch() {
        this.displaySearch = false;
        this.displayResult = true;
        this.displayWorkorder = false;
        this.displaySerial = false;
        this.searching = true;
        this.customers = [];
        localStorage.setItem('phoneNumber', this.phoneNumber);
        this.customerService.getCustomerByPhone(this.phoneNumber).subscribe((data: Customer[]) => {
            this.endSearch(data);
        }, err => {
            this.searching = false;
        });
    }

    private doEmailSearch() {
        this.displaySearch = false;
        this.displayResult = true;
        this.displayWorkorder = false;
        this.displaySerial = false;
        this.searching = true;
        this.customers = [];
        localStorage.setItem('email', this.email);
        this.customerService.getCustomerByEmail(this.email).subscribe((data: Customer[]) => {
            this.endSearch(data);
        }, err => {
            this.searching = false;
        });
    }

    private doSerialnoSearch() {
        this.dateMismatch = false;
        this.displaySerial = true;
        this.displayWorkorder = false;
        this.workorderSearching = true;
        this.workorders = [];
        localStorage.setItem('serialno', this.serialno);
        this.customerService.getCustomerBySerialno(this.serialno).subscribe((res: []) => {

            console.log("serialno results:",res);

            if (res !== null || res.length > 0) {
                this.endWorkorderSearch(res);
            }

        }, err => {
            this.workorderSearching = false;
        })

    }

    private doPSDJobnoSearch() {
        this.dateMismatch = false;
        this.displaySerial = true;
        this.displayWorkorder = false;
        this.workorderSearching = true;
        this.workorders = [];
        localStorage.setItem('jobno', this.jobno);
        this.customerService.getCustomerByPSDJobId(this.jobno).subscribe((res: []) => {

            console.log("jobno results:", res);

            if (res !== null || res.length > 0) {
                this.endWorkorderSearch(res);
            }

        }, err => {
            this.workorderSearching = false;
        })

    }


    private doAddressSearch() {
        this.displayResult = true;
        this.searching = true;
        this.displayWorkorder = false;
        this.displaySerial = false;
        this.customers = [];
        localStorage.setItem('FirstName', this.addressCriteria.FirstName);
        localStorage.setItem('LastName', this.addressCriteria.LastName);
        localStorage.setItem('FullName', this.addressCriteria.FullName);
        localStorage.setItem('Apartment', this.addressCriteria.Apartment);
        localStorage.setItem('City', this.addressCriteria.City);
        localStorage.setItem('State', this.addressCriteria.State);
        localStorage.setItem('StreetAddressName', this.addressCriteria.StreetAddressName);
        localStorage.setItem('StreetAddressNumber', this.addressCriteria.StreetAddressNumber);
        localStorage.setItem('Zipcode', this.addressCriteria.Zipcode);
        this.customerService.getCustomerByAddress(this.addressCriteria).subscribe((data: Customer[]) => { this.endSearch(data) }, err => {

            this.searching = false;
        });
    }

    private doAccountSearch() {
        this.displayWorkorder = false;
        this.displaySerial = false;
        this.displayResult = true;
        this.searching = true;
        this.customers = [];
        localStorage.setItem('accountNumber', this.accountNumber);
        this.customerService.getCustomerByAccountNo(this.accountNumber).subscribe((data: Customer[]) => {
            this.endSearch(data);
        }, err => {

            this.searching = false;
        });
    }

    endWorkorderSearch(workorder) {
        this.workorders = [];
        for (let i = 0; i < workorder.length; i++) {
            const c = new Workorder();
            c.setFromWeb(workorder[i]);
            this.workorders.push(c);
        }
    
        this.dataSource1.data = this.workorders;
        console.log(this.workorders);
        this.workorderSearching = false;
    }

    


    endSearch(cust) {
        this.customers = [];

        for (let i = 0; i < cust.length; i++) {
            const c = new Customer();
            c.setUpFromWS(cust[i]);
            this.customers.push(c);
        }
        this.dataSource.data = this.customers;
        this.searching = false;
    }

    navigateToBucketSchedule(workorder){
       /*  if(workorder.StatusNumber == 190){  */  
          
            this.router.navigate(['bucketSchedule',workorder.Id]);
      //  }
    }

    navigateToSchedule(workorder){
        this.workorderService.currentWorkorderID = workorder.Id;
        this.workorderService.currentWorkorder = workorder;
        this.locationSearchService.grid=null;
        this.locationSearchService.personcompany=null;
        this.locationSearchService.scheduledate=null;
        this.locationSearchService.schdate = null;
        this.locationSearchService.workorderID=null;
        if(workorder.StatusNumber == 190){   
            this.workorderService.workorderStatusCode = 'schedule'
            this.router.navigate(['scheduling',workorder.Group,workorder.SubGroup,workorder.Id,'schedule'])
        }
        else if(workorder.StatusNumber ==200){
            this.workorderService.workorderStatusCode = 'reschedule'
            this.router.navigate(['scheduling',workorder.Group,workorder.SubGroup,workorder.Id,'reschedule'])
        }
       
    }

    handleWorkorderClick(workorder: Workorder) {
        console.log("test1");
        this.dataTransfer.myMethod(true);
        if (this.utilityService.currentProgramSponsor.SourceId === 'BBCS') {
            this.navToWorkorderDetail(workorder);
        }
        else {

            this.workorderService.getWorkorderDetailsByWorkorderId(workorder.Id, workorder.CustomerId).subscribe(detailWorkorder => {
                if (detailWorkorder === undefined || detailWorkorder === null) {
                    this.handleError('Failed to retrieve workorder');
                } else {
                    this.navToWorkorderDetail(detailWorkorder);
                }
            });
        }

    }


    handleClick(cust: Customer) {
        let className = localStorage.getItem("themeName");
        this.dataTransfer.myMethod(true);

        if (cust.FromMaster) {

            const dialogRef = this.dialog.open(WaitDialogComponent,
                {
                    data:
                    {
                        caption: 'Wait while master file record is saved as Customer'
                    },panelClass:className
                });

            this.customerService.createCustomer(cust).subscribe(data => {
                if (data.success) {
                    this.customerService.getCustomerById(data.id).subscribe(newCust => {
                        if (newCust === undefined || newCust === null) {
                            dialogRef.close();
                            this.handleError('Failed to retrieve new customer');
                        } else {
                            dialogRef.close();
                            this.navToDetail(newCust);
                        }
                    });
                } else {
                    dialogRef.close();
                    this.handleError(data.errors);
                }
            }, err => {
                dialogRef.close();
                this.handleError(err);
            });
        } else {
            this.customerService.getCustomerById(cust.Id).subscribe(detailCust => {
                if (detailCust === undefined || detailCust === null) {
                    this.handleError('Failed to retrieve customer');
                } else {
                    this.navToDetail(detailCust);
                }
            });
        }
    }

    navToDetail(cust: any) {

        const tempcust: Customer = new Customer();
        tempcust.setUpFromWS(cust);
        this.customerService.currentCustomer = tempcust;
        this.customerService.storedCustomers(tempcust);
        this.router.navigate(['/detail']);
    }


    navToWorkorderDetail(workorder: any) {
        const tempWorkorder: Workorder = new Workorder();
        tempWorkorder.isWorkorder = true;
        if (this.utilityService.currentProgramSponsor.SourceId === 'BBCS') {
            this.workorderService.currentWorkorderID = workorder.Id;
            this.enrollmentService.currentEnrollmentID = workorder.EnrollmentId;
            tempWorkorder.Name = workorder.Name;
            workorder.ProgramSponsorId = this.utilityService.currentProgramSponsor.ProgramSponsorId;
            tempWorkorder.setFromWeb(workorder);
            this.workorderService.currentWorkorder = tempWorkorder;
            this.customerService.storedCustomers(tempWorkorder);
            if(workorder.Group == null){
                workorder.Group = "DEFAULT";
            }
            this.router.navigate(['workorderGetSave',workorder.Group,workorder.SubGroup, workorder.Id, workorder?.SubGroup1 ?? "ALL"])
        }
        else {

            tempWorkorder.Name = workorder.WorkorderName;
            tempWorkorder.setFromWeb(workorder);
            this.workorderService.currentWorkorder = tempWorkorder;
            this.customerService.storedCustomers(tempWorkorder);
            this.router.navigate(['/workorderDetail']);
        }





    }

    doClear() {
        this.phoneNumber = "";
        this.accountNumber = "";
        this.email = '';
        this.serialno = '';
        this.jobno = '';
        this.addressCriteria.FirstName = "";
        this.addressCriteria.LastName = "";
        this.addressCriteria.FullName = "";
        this.addressCriteria.Apartment = "";
        this.addressCriteria.City = "";
        this.addressCriteria.State = "";
        this.addressCriteria.StreetAddressName = "";
        this.addressCriteria.StreetAddressNumber = "";
        this.addressCriteria.Zipcode = "";
        this.workorderName = "";
        this.selectedProgram = null;
        this.selectedStatus = null;
        this.startDate = undefined;
        this.endDate = undefined;
    }

    handleError(mesg: string) {
        Swal.fire({
            title: 'Error',
            text: mesg,
            icon: 'error',
            confirmButtonText: 'OK',
        })
    }

    enrollCustomer(){
        this.router.navigate(['enrollcustomer']);
    }
}

export class Programs {
    ProgramName: string;
    ProgramId: string;
}

export class Status {
    JobStatusNumber: number;
    JobStatus: string;
}
